<template>
  <div class="mainContent" :style="`zIndex: ${zIndex}`">
    <div class="mainMask" :style="`opacity:${mainOpacity}`"></div>
    <!-- <img
      class="mainContent-prelink"
      @click="handleOpenLink('blessing')"
      src="@/assets/images/main/pre_link.png"
      alt=""
    /> -->
    <div class="bg">
      <img class="role1" src="@/assets/images/bg/bg_01_2.0_role1.png" />

      <div class="titleView">
        <img class="titleView-main" src="@/assets/images/bg/title.png" />
        <div class="btn-list">
          <div class="titleView-button style1" @click="handleOpenLink('discord')">
            Join Discord
          </div>
          <div class="titleView-button style2" @click="handleOpenLink('market')">
            Buy NFT
          </div>
          <div class="titleView-button style3" @click="handleOpenLink('mintArt')">
            Mint ART
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <!-- <img class="title" src="@/assets/images/main/art_title.png" alt="" />
      <img class="subTitle" src="@/assets/images/main/art_subTitle.png" alt="" /> -->
      <!-- <Lottie class="title" :options="titleOption" /> -->

      <!-- <div class="steps">
        <div class="steps-item">
          <span class="steps-item-num">01</span> 
          <span class="steps-item-text">Complete Quests</span>
        </div>
          <div class="steps-item">
          <span class="steps-item-num">02</span>
          <span class="steps-item-text">Collect Yuli Points</span>
          </div>
        <div class="steps-item">
          <span class="steps-item-num">03</span>
          <span class="steps-item-text">Get rewarded handsomely</span>
        </div>
      </div> -->
      <!-- <div class="contentMainLink">
        <div class="mainLink">
          <div class="mainLink-right" @click="handleOpenLink('getYuli')">
            <div class="mainLink-right-button1">Mint Now</div>
            <div class="mainLink-right-button2">Mint Now</div>
          </div>
          <div class="mainLink-left" @click="handleOpenLink('whitePaper')">
            <div class="mainLink-left-button1">Whitepaper</div>
            <div class="mainLink-left-button2">Whitepaper</div>
          </div>
        </div>
      </div> -->

      <!-- <div class="toArt" @click="handleOpenLink('art')"> Start Now</div> -->
    </div>
    <!-- <div class="appLink">
        <img
          class="appLink-ios"
          @click="handleOpenLink('ios')"
          src="@/assets/images/main/ios.png"
        />
        <img
          class="appLink-google"
          @click="handleOpenLink('google')"
          src="@/assets/images/main/google.png"
        />
      </div> -->
    <!-- <img
      class="yuliPic"
      :style="`transform: translateY(${translateY}px)`"
      :src="require('@/assets/images/main/role_yuli.png')"
      alt=""
    /> -->
  </div>
</template>

<script>
import titleJson from "@/assets/json/title.json";
import Lottie from "vue-lottie/src/lottie.vue";
import { ref } from "@vue/reactivity";
import axios from "axios";

export default {
  // components: {
  //   Lottie,
  // },
  props: {
    mainOpacity: {
      type: Number,
      default: 0,
    },
    zIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      translateY: 0,
      participantsNumber: 0,
      titleOption: { animationData: titleJson },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler, true);
    // this.getData();
  },
  methods: {
    // getData() {
    //   axios
    //     .get(
    //       `https://yf.yuliverse.com/${
    //         process.env.NODE_ENV === "production" ? "prod" : "beta"
    //       }/hero_wl.json`
    //     )
    //     .then(
    //       (response) => {
    //         this.participantsNumber = response.data.total;
    //       },
    //       (response) => {
    //         this.participantsNumber = 0;
    //       }
    //     );
    // },
    handleOpenLink(str) {
      switch (str) {
        case "whitePaper":
          window.open(
            "https://metaport.gitbook.io/the-white-paper-of-yuliverse/"
          );
          break;
        case "getYuli":
          window.open(
            process.env.NODE_ENV === "production"
              ? "https://special-event.yuliverse.com/PolygonHero"
              : "https://umi.yuliverse.com/PolygonHero"
          );
          break;
        case "blessing":
          window.open("https://special-event.yuliverse.com/UnknownGuest");
          break;
        case "ios":
          window.open("https://apps.apple.com/hk/app/yuliverse/id6469319748");
          break;
        case "google":
          window.open(
            "https://play.google.com/store/apps/details?id=com.blockoor.yuliforoverseas"
          );
          break;
        case "art":
          window.open("https://airdrop.yuliverse.com/");
          break;
        case "Anni":
          window.open(
            process.env.NODE_ENV === "production"
              ? "https://special-event.yuliverse.com/Annihilation"
              : "https://umi.yuliverse.com/Annihilation"
          );
          break;
        case "discord":
          window.open("https://discord.com/invite/yuliverse-official");
          break;
        case "market":
          window.open("https://marketplace.yuliverse.com/");
          break;
        case "mintArt":
          window.open("https://special-event.yuliverse.com/ART/mint");
          break;
      }
    },
    scrollHandler() {
      const scrollTop =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;

      if (scrollTop >= 0) {
        this.translateY = -(scrollTop / 5);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/common/less/webpbg.less");

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  width: 100%;
  text-align: center;
  min-height: 891px;
  width: (100dvh / 891px) * 1920px;
  max-width: 100dvw;
  height: 100dvh;
  // .webpbg("~@/assets/images/version2/bg/bg_1.png");
  background-color: #0E0E22;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .mainMask {
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  }
  &-prelink {
    width: 436px;
    height: 347px;
    top: 298px;
    right: 0;
    position: absolute;
    cursor: pointer;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }

  .appLink {
    position: absolute;
    bottom: 250px;
    display: flex;
    align-items: center;
    &-ios {
      width: 164px;
      height: 60px;
      margin-right: 10px;
      cursor: pointer;
    }
    &-google {
      width: 202px;
      height: 60px;
      cursor: pointer;
    }
  }

  .bg {
    position: absolute;
    left: 0px;
    right: 0px;
    height: 100dvh;

    .role1 {
      width: calc(1154 / 1920 * 100vw);
      height: auto;
      position: absolute;
      top: calc(0.0288 * 100vh);
      right: calc(0.0229 * 100vw);
      z-index: 1;
    }

    .titleView {
      width: fit-content;
      position: absolute;
      top: calc(0.2455 * 100vh);
      left: calc(0.0333 * 100vw);
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 0;

      &-main {
        width: calc(701 / 1920 * 100vw);
        height: auto;
      }

      .btn-list {
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 15%;
      }
      
      &-button{
        width: 160px;
        height: 40px;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
        font-family: "Tomorrow";
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: 100% 100%;
        margin-right: 20px;
        &:last-child{margin-right: unset;}

        &.style1{
          background-image: url("~@/assets/images/main/btn_blue_s_272@2x.png");
        }
        &.style2{
          background-image: url("~@/assets/images/main/new_btn_bg.png");
        }
        &.style3{
          background-image: url("~@/assets/images/main/btn_white.png");
        }
      }
    }
  }
  .content {
    position: absolute;
    top: 19%;
    // right: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    width: 950px;
    .title {
      // flex-grow: 2;
      width: 469px;
      height: 86px;
      font-size: 80px;
      font-weight: 700;
      font-family: "Josefin Sans";
      text-shadow: 0 0 57px #ff00e6, 0 0 30px #9e00ff;
      // -webkit-transform: scale(1.1); /* Chrome, Safari, Opera */
      // -moz-transform: scale(1.1); /* Firefox */
      // transform: scale(1.1); /* Modern Browsers */
    }
    .subTitle {
      margin-top: 10px;
      width: 757px;
      height: 83px;
    }

    .steps {
      margin-top: 30px;
      display: flex;

      &-item {
        width: 246px;
        height: 73px;
        background-image: url("~@/assets/images/main/step_bg.png");
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        text-align: left;
        &-num {
          color: #c14600;
          text-align: center;
          font-family: "Josefin Sans";
          font-size: 42px;
          font-weight: 700;
          line-height: 22.81px;
          opacity: 0.9;
        }
        &-text {
          margin-left: 10px;
          width: 164px;
          color: #e7993e;
          font-size: 25px;
          font-style: normal;
          font-weight: 700;
          line-height: 25px;
        }
      }
    }
    .steps-item + .steps-item {
      margin-left: 20px;
    }

    .toArt {
      cursor: pointer;
      margin-top: 60px;
      width: 281px;
      height: 60px;
      border-radius: 34px;
      border: 1px solid #710404;
      background: #c14600;
      line-height: 60px;
      text-align: center;
      color: #ffffff;
      text-align: center;
      font-family: "Josefin Sans";
      font-size: 28px;
      font-weight: 700;
    }

    .contentMainLink {
      width: 436px;
      height: 127px;
      border-radius: 8px;
      opacity: 1;
      padding: 16px;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.7);
      margin-top: 80px;
      display: flex;
      align-items: center;
      .mainLinkText {
        color: rgb(0, 240, 255);
        font-size: 24px;
        font-weight: 700;
        font-family: "Josefin Sans";
        text-align: center;
        margin-bottom: 16px;
      }
    }
  }

  .mainLink {
    // margin-left: 50px;
    position: relative;
    width: 404px;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    z-index: 1;
    &-left,
    &-right {
      cursor: pointer;
    }
    &-left {
      position: relative;
      box-sizing: border-box;
      overflow: hidden;
      transition: 0.7s;
      width: 188px;
      height: 55px;
      // margin-right: 20px;
      padding: 0 0 13px 32px;
      border-radius: 61px;
      border: 1px solid rgba(255, 255, 255, 1);
      // background-color: rgba(32, 32, 61, 0.8);
      &-button2 {
        box-sizing: border-box;
        width: 188px;
        height: 55px;
        padding: 14px 0 13px 14px;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(0, 0);
        transition: 0.5s ease-out;

        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        font-family: "Josefin Sans";
        font-weight: 700;
        line-height: 30px;
        background: url("~@/assets/images/main/icon_whitepappers.svg") no-repeat
          20px 15px;
        background-size: 24px;
      }
      &-button1 {
        box-sizing: border-box;
        width: 188px;
        height: 55px;
        padding: 14px 0 13px 14px;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-100%, 0);
        transition: 0.5s ease-out;

        background: url("~@/assets/images/main/icon_whitepappers_black.svg")
          no-repeat 20px 15px;
        background-size: 24px;
        color: rgba(63, 59, 94, 1);
        font-size: 18px;
        font-weight: 700;
        font-family: "Josefin Sans";
        line-height: 30px;
      }
    }
    .mainLink-left:hover {
      background-color: #ecf0f1;
    }
    .mainLink-left:hover .mainLink-left-button1 {
      transform: translate(0, 0);
    }
    .mainLink-left:hover .mainLink-left-button2 {
      transform: translate(100%, 0);
    }
    &-right {
      position: relative;
      box-sizing: border-box;
      width: 192px;
      height: 55px;
      padding: 0 0 13px 14px;
      border-radius: 63px;
      border: 1px solid rgba(0, 102, 255, 0.4);
      background-color: #00f0ff;
      overflow: hidden;
      transition: 0.7s;
      &-button2 {
        box-sizing: border-box;
        width: 192px;
        height: 55px;
        padding: 14px 0 13px 14px;
        background: url("~@/assets/images/main/icon_play_black.svg") no-repeat
          30px 15px;
        background-size: 24px;
        color: #202020;
        font-size: 18px;
        font-family: "Josefin Sans";
        font-weight: 900;
        text-align: center;
        line-height: 30px;

        position: absolute;
        left: 0;
        top: 0;
        transform: translate(0, 0);
        transition: 0.5s ease-out;
      }
      &-button1 {
        box-sizing: border-box;
        width: 192px;
        height: 55px;
        padding: 14px 0 13px 14px;
        background: url("~@/assets/images/main/icon_play_black.svg") no-repeat
          30px 15px;
        background-size: 24px;
        color: rgba(63, 59, 94, 1);
        font-size: 18px;
        font-weight: 900;
        font-family: "Josefin Sans";
        text-align: center;
        line-height: 30px;

        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-100%, 0);
        transition: 0.5s ease-out;
      }
      .tips {
        z-index: 2;
        position: fixed;
        margin-top: 30px;
        &-icon {
          width: 28px;
          height: 28px;
          animation: ScaleIcon 2s linear 2s infinite;
        }
        &-tip {
          line-height: 58px;
          font-size: 18px;
          color: #353a5f;
          font-family: "Josefin Sans";
          font-weight: 900;
          width: 143px;
          height: 47px;
          background-image: url("~@/assets/images/main/bg_tips.png");
          background-size: 100% 100%;
        }
      }
    }
    .mainLink-right:hover {
      background-color: rgba(255, 255, 255, 1);
    }
    .mainLink-right:hover .mainLink-right-button1 {
      transform: translate(0, 0);
    }
    .mainLink-right:hover .mainLink-right-button2 {
      transform: translate(100%, 0);
    }
  }

  @keyframes ScaleIcon {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
  .yuliPic {
    position: absolute;
    left: 0;
    bottom: 10%;
    // width: 58%;
    height: 80%;
    // min-width: 960px;
    // max-width: 1120px;
  }
}

// @media screen and(min-width: 1920px) {
//   .mainContent {
//     background-size: 100% 130%;
//     background-position: 0;
//   }
//   .content {
//     position: absolute;
//     // right: 20%;
//     display: flex;
//     align-items: center;
//     width: 1200px;
//     .title {
//       -webkit-transform: scale(1.2); /* Chrome, Safari, Opera */
//       -moz-transform: scale(1.2); /* Firefox */
//       transform: scale(1.2); /* Modern Browsers */
//       // width: 824 * 1.3px;
//       // height: 220 * 1.3px;
//       font-size: 80 * 1.3px;
//     }
//     .appLink {
//       &-ios {
//         width: 164 * 1.2px;
//       }
//       &-google {
//         width: 202 * 1.2px;
//       }
//     }
//   }
//   // .mainLink {
//   //   -webkit-transform: scale(1.2); /* Chrome, Safari, Opera */
//   //   -moz-transform: scale(1.2); /* Firefox */
//   //   transform: scale(1.2); /* Modern Browsers */
//   //   // margin-left: 80px;
//   // }
//   .contentMainLink {
//     -webkit-transform: scale(1.2); /* Chrome, Safari, Opera */
//     -moz-transform: scale(1.2); /* Firefox */
//     transform: scale(1.2); /* Modern Browsers */
//     // margin-left: 80px;
//   }
// }

@media screen and(max-width: 1920px){
  .mainContent{
    .bg{
      .role1{
        top: 50%;
        transform: translateY(-50%);
      }
      .titleView{
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

@media screen and(max-width: 1320px){
  .mainContent{
    .bg{
      .titleView{
        z-index: 2;
      }
    }
  }
}
</style>
