<template>
  <div class="content">
    <div class="main" :style="{ scale: scale }">
      <div
        :class="[
          'content-header',
          'fadein scrollin',
          animationValueMap.isToken2In && 'delay500',
        ]"
        id="token1"
      >
        <img
          class="title-decorate"
          :src="require('@/assets/images/version2/token/title_bg.png')"
        />
        <div class="title">{{ title }}</div>
      </div>

      <div class="content-block">
        <div :class="['item-block', 'item-block-arg']">
          <img
            :class="[
              'item-block-img',
              'fadein scrollin',
              animationValueMap.isToken2In && 'delay900',
            ]"
            :src="require('@/assets/images/version2/token/arg_bg.png')"
          />
          <div class="item-block-content">
            <div
              :class="[
                'fadein scrollin',
                animationValueMap.isToken2In && 'delay900',
              ]"
            >
              {{ `$YULI with be the main platform token for YuliGO Social Platform. Earn by posting, liking and sharing great content. POP404 (Proof of Participation 404) standard allows content created on YuliGo to be traded on secondary market as fungible token, amplified by socialfi and network effects.\nWithin the Yuliverse game, $YULI can be earned by completing quests and consumed when players Upgrade, Breed, and Rebirth their character NFTs.` }}
            </div>
          </div>
        </div>
        <img
          :class="[
            'content-img',
            'fadein scrollin',
            animationValueMap.isToken2In && 'delay700',
          ]"
          :src="require('@/assets/images/version2/token/content_bg.png')"
        />
        <div :class="['item-block', 'item-block-art']" id="token2">
          <img
            :class="[
              'item-block-img',
              'fadein scrollin',
              animationValueMap.isToken2In && 'delay900',
            ]"
            :src="require('@/assets/images/version2/token/art_bg.png')"
          />
          <div class="item-block-content">
            <div
              :class="[
                'fadein scrollin',
                animationValueMap.isToken2In && 'delay900',
              ]"
            >
              $ARG earned through quests and exploration, used for character
              upgrades and etc
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "YuliToken",
  props: {},
  data() {
    return {
      title: "Yuliverse Token",
      animationList: [
        { id: "token1", key: "isToken1In" },
        { id: "token2", key: "isToken2In" },
      ],
      animationValueMap: {
        isToken1In: false,
        isToken2In: false,
      },
      scale: 1,
    };
  },
  computed: {},
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.handleInitAnim();
  },
  beforeUnmount() {},
  methods: {
    handleInitAnim() {
      const observer = new IntersectionObserver((mutaions) => {
        for (const i in mutaions) {
          const isIn = mutaions[i]?.isIntersecting;
          const target = mutaions[i]?.target;
          const id = target?.id;

          const item = this.animationList.find((item) => {
            return item.id === id;
          });

          const key = item?.key;
          if (!key) continue;
          const value = this.animationValueMap[key];

          if (isIn && !value) {
            this.animationValueMap[key] = true;
          } else if (!isIn && value) {
            this.animationValueMap[key] = false;
          }
        }
      });

      for (const i in this.animationList) {
        const id = this.animationList[i].id;
        const el = document.querySelector(`#${id}`);
        if (el) {
          observer.observe(el);
        }
      }
    },
    handleResize() {
      const width = document.body.clientWidth;

      let size = width / 1920;
      size = size < 1 ? 1 : size;
      this.scale = size;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/common/less/webpbg.less");
@import url("~@/common/animation.less");

.content {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: (100dvw / 1920px) * 720px;
  min-height: 720px;
  .webpbg("~@/assets/images/version2/bg/bg_shraptoken.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.main {
  width: 1170px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.content-header {
  // margin-top: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  position: relative;

  .title-decorate {
    width: 423px;
    height: 48px;
    position: absolute;
    left: 0;
    top: 43px;
  }

  .title {
    width: 423px;
    color: #000000;
    font-family: "Tomorrow";
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 60.14px;
    position: relative;
    text-align: left;
  }
}

.content-block {
  margin-top: 38px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  .item-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .item-block-img {
      width: 163px;
      height: 44px;
      margin-bottom: 12px;
    }

    .item-block-content {
      padding: 8px;
      box-sizing: border-box;
      border-radius: 4px;
      background: #ffffffcc;
      opacity: 0.8;
      color: #000000D9;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      position: relative;
      z-index: 1;
      white-space: pre-line;
    }

    &-arg {
      margin-top: 180px;

      .item-block-content {
        width: 427px;
        height: auto;
      }
    }

    &-art {
      margin-top: 80px;
      margin-left: 2px;

      .item-block-content {
        width: 327px;
        height: 120px;
      }
    }
  }

  .content-img {
    width: 458px;
    height: 420px;
    position: absolute;
    left: 33%;
    z-index: 0;
  }
}
</style>
